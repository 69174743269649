var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dialog-form',{attrs:{"model":_vm.getNewShrimpTalk(),"schema":_vm.shrimpTalkFields,"showDialog":_vm.dialogVisible,"loading":_vm.dialogBoxLoading,"title":"Add ShrimpTalk","backendValidations":_vm.createSTFormErrors,"deviceType":"SHRIMP_TALK","shouldErrorHighlightShow":true},on:{"close-dialog":_vm.closeDialog,"save-details":_vm.handleSaveNewShrimpTalk,"clear-validations":_vm.handleClearValidations}}),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"erDataTables",staticClass:"is-filters-set-absolute",attrs:{"tableProps":_vm.elTableProps,"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.columnsObject,"total":_vm.total,"addPadingToSearch":true,"button":true,"group_settings":true,"columnselector_require":true,"selectedColumnHeadersSortable":true,"search-property":"device_code","placeHolderForSearch":"Search_device_code"},on:{"cell-click":_vm.handleCellClick,"reload":_vm.handleLoadData,"loadData":_vm.handleLoadData,"cleared":_vm.handleLoadData,"add-item-click":_vm.handleAddClick},scopedSlots:_vm._u([{key:"shrimptalk_id",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":("/admin/shrimptalks/" + (row.data._id))}},[_vm._v("\n        "+_vm._s(_vm.stIdString + row.data.v1_code)+"\n      ")])]}},{key:"v1_id",fn:function(ref){
var row = ref.row;
return [_vm._v("\n      "+_vm._s(row.data.v1_id)+"\n      "+_vm._s(row.data.v1_code ? (" (" + (row.data.v1_code) + ")") : "")+"\n    ")]}},{key:"pondmothers",fn:function(ref){
var row = ref.row;
return [(row.data.pond_mothers.length > 0)?_vm._l((row.data.pond_mothers),function(feeder,index){return _c('router-link',{key:index,class:feeder.is_master ? 'dark-tag' : 'light-tag',staticStyle:{"color":"#ffffff"},attrs:{"to":("/admin/pondmothers/" + (feeder._id)),"effect":feeder.is_master ? 'dark' : 'light',"title":feeder.is_master ? 'master' : 'non master'}},[_vm._v("\n          "+_vm._s(feeder.code)+"\n        ")])}):_vm._e()]}},{key:"v2_parsing_enabled",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"value":row.data.settings.v2_parsing ? row.data.settings.v2_parsing : false},on:{"input":function($event){return _vm.handleASettingKeyChange($event, row.data, 'v2_parsing')}}})]}},{key:"v1_parsing_enabled",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"value":row.data.settings.v1_parsing ? row.data.settings.v1_parsing : false},on:{"input":function($event){return _vm.handleASettingKeyChange($event, row.data, 'v1_parsing')}}})]}},{key:"feeding_model",fn:function(ref){
var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.getFeedingModelVal(row.data.settings.feeding_model))+"\n    ")]}},{key:"pm_gateway_codes",fn:function(ref){
var row = ref.row;
return [(_vm.getArrayOfFeederGatewayID(row.data.settings).length > 0)?_vm._l((_vm.getArrayOfFeederGatewayID(
            row.data.settings
          )),function(feeder,index){return _c('el-tag',{key:index,class:index === 0 ? 'dark-tag1' : 'light-tag1',attrs:{"title":index === 0 ? 'feeder 1' : 'feeder 2'}},[_vm._v("\n          "+_vm._s(feeder)+"\n        ")])}):[_vm._v("--")]]}},{key:"pm_gateway_ips",fn:function(ref){
          var row = ref.row;
return [(_vm.getArrayOfFeederGatewayID(row.data.settings).length > 0)?_vm._l((_vm.getArrayOfFeederGatewayIP(
            row.data.settings
          )),function(feeder,index){return _c('el-tag',{key:index,attrs:{"size":"mini","effect":"dark","title":index === 0 ? 'feeder 1' : 'feeder 2'}},[_vm._v(_vm._s(feeder))])}):[_vm._v("--")]]}},{key:"pm_gateway_channels",fn:function(ref){
          var row = ref.row;
return [(_vm.getArrayOfFeederGatewayChannel(row.data.settings).length > 0)?[_vm._l((_vm.getArrayOfFeederGatewayChannel(
            row.data.settings
          )),function(feeder,index){return _c('el-tag',{key:index,class:index === 0 ? 'dark-tag2' : 'light-tag2',attrs:{"size":"mini","effect":"dark","title":index === 0 ? 'feeder 1' : 'feeder 2'}},[_vm._v(_vm._s(feeder))])}),_vm._v(" "),_c('br')]:[_vm._v("--")]]}},{key:"pondguard_enabled",fn:function(ref){
          var row = ref.row;
return [_c('el-switch',{attrs:{"value":row.data.settings.pg_enable ? row.data.settings.pg_enable : false},on:{"input":function($event){return _vm.handleASettingKeyChange($event, row.data, 'pg_enable')}}})]}},{key:"installed_date",fn:function(ref){
          var row = ref.row;
return [_vm._v(_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.on_boarded_at))))]}},{key:"module_name",fn:function(ref){
          var row = ref.row;
return [_vm._v(_vm._s(_vm.ftm__formatEmpty(row.data.settings.module_name)))]}},{key:"module_size",fn:function(ref){
          var row = ref.row;
return [_vm._v(_vm._s(_vm.ftm__formatEmpty(row.data.settings.module_size)))]}},{key:"user_details",fn:function(ref){
          var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.ftm__objectValueByPath(
          row.data.user_details,
          "full_name",
          _vm.ftm__formatEmpty("--")
        ))+"\n    ")]}},{key:"last_communication",fn:function(ref){
        var row = ref.row;
return [(row.data.utc_last_communicated_at_status)?_c('el-tag',{attrs:{"size":"mini","type":"success","effect":"dark"}},[_vm._v("\n        "+_vm._s(_vm.ftm__formatEmpty(
            _vm.adm__filterToFormatDate(row.data.utc_last_communicated_at)
          ))+"\n      ")]):[_vm._v("\n        "+_vm._s(_vm.ftm__formatEmpty(
            _vm.adm__filterToFormatDate(row.data.utc_last_communicated_at)
          ))+"\n      ")]]}},{key:"time_zone_details",fn:function(ref){
          var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.getTimeZoneData(row.data.user_details))+"\n    ")]}},{key:"block_st",fn:function(ref){
          var row = ref.row;
return [_c('el-switch',{attrs:{"disabled":"","value":row.data.block_shrimp_talk}})]}}])},[_c('template',{slot:"add-buttton"},[_vm._v(" Add ShrimpTalk ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }