var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"add-devices-to-user",attrs:{"width":"30%","visible":_vm.dialogVisible,"close-on-click-modal":false,"append-to-body":true,"title":((_vm.$t(_vm.action === 'ADD_DEVICES' ? 'Comn_add' : 'Comn_delete')) + " Devices"),"custom-class":"add-devices-to-user-dialog","element-loading-background":"white"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"beforeClose":_vm.handleCloseDialog,"open":_vm.initComponent}},[_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{attrs:{"size":"small"}},[_c('el-form-item',{attrs:{"label-width":"30px","label":"Customer Name"}},[_c('el-col',[_vm._v("\n          "+_vm._s(this.customer_details.first_name +
              " " +
              this.customer_details.last_name)+"\n        ")])],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-item-select",attrs:{"name":"PondGuards"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_pond_guard'),"error":errors[0]}},[_c('el-col',[_c('er-select',{staticClass:"select-devices",attrs:{"reserve-keyword":true,"showSelectAll":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"loading":_vm.sLoading === 'POND_GUARD',"remote-method":_vm.fetchPondGuardByCode,"placeholder":"Enter PondGuard Code","no-data-text":_vm.$t('Comn_no_pondguards')},model:{value:(_vm.selectedPGs),callback:function ($$v) {_vm.selectedPGs=$$v},expression:"selectedPGs"}},_vm._l((_vm.pond_guards),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-item-select",attrs:{"name":"PondMothers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_pond_mother'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"reserve-keyword":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"remote-method":_vm.fetchPondMotherByCode,"loading":_vm.sLoading === 'POND_MOTHER',"placeholder":"Enter PondMother Code","no-data-text":_vm.$t('Comn_no_pondmothers')},model:{value:(_vm.selectedPMs),callback:function ($$v) {_vm.selectedPMs=$$v},expression:"selectedPMs"}},_vm._l((_vm.pond_mothers),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-item-select",attrs:{"name":"Shrimptalk"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_shrimptalk'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","reserve-keyword":true,"collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"remote-method":_vm.fetchShrimpTalkByCode,"loading":_vm.sLoading === 'SHRIMP_TALK',"placeholder":"Enter ShrimpTalk Code","no-data-text":_vm.$t('Comn_no_shrimptalks')},model:{value:(_vm.selectedSTs),callback:function ($$v) {_vm.selectedSTs=$$v},expression:"selectedSTs"}},_vm._l((_vm.shrimp_talks),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Gateway"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_gateway'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","reserve-keyword":true,"collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"remote-method":_vm.fetchGatewayByCode,"loading":_vm.sLoading === 'GATEWAY',"placeholder":"Enter Gateway Code","no-data-text":"No Gateways"},model:{value:(_vm.selectedGWs),callback:function ($$v) {_vm.selectedGWs=$$v},expression:"selectedGWs"}},_vm._l((_vm.gateways),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Feedblower"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('devices.feed-blower'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"reserve-keyword":true,"remote-method":_vm.fetchFeedBlowerByCode,"loading":_vm.sLoading === 'FEEDBLOWER',"placeholder":"Enter Feed Blower Code","no-data-text":"No Feed Blowers"},model:{value:(_vm.selectedFBs),callback:function ($$v) {_vm.selectedFBs=$$v},expression:"selectedFBs"}},_vm._l((_vm.feed_blowers),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_vm._v("\n                "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")\n              ")])}),1)],1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"ShrimpSnap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":"ShrimpSnap","error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"reserve-keyword":true,"remote-method":_vm.fetchShrimpSnapByCode,"loading":_vm.sLoading === 'SHRIMP_SNAP',"placeholder":"Enter ShrimpSnap Code","no-data-text":"No ShrimpSnap"},model:{value:(_vm.selectedSSs),callback:function ($$v) {_vm.selectedSSs=$$v},expression:"selectedSSs"}},_vm._l((_vm.shrimp_snaps),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_vm._v("\n                "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")\n              ")])}),1)],1)],1)]}}])})],1)],1),_vm._v(" "),_c('layout-toolbar',{attrs:{"slot":"footer","justify":"center"},slot:"footer"},[_c('er-button',{attrs:{"size":"mini","btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.loading},on:{"click":_vm.submitDeviceDetails}}),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","btnType":"cancel","showLabel":true},on:{"click":_vm.handleCloseDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }