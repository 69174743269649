var render, staticRenderFns
import script from "./adminPageAccessComponent.vue?vue&type=script&lang=js&"
export * from "./adminPageAccessComponent.vue?vue&type=script&lang=js&"
import style0 from "./adminPageAccessComponent.vue?vue&type=style&index=0&id=e5314dfa&lang=scss&scoped=true&"
import style1 from "./adminPageAccessComponent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5314dfa",
  null
  
)

export default component.exports